import * as Fhir from "./../../resources/classes/FhirModules/Fhir";
import {Tools} from "./../../resources/classes/FhirModules/Fhir";
import {PatientItem} from "./../../resources/classes/Patient/PatientItem";
import {PatientService} from "./PatientService";
import {fhirEnums} from "../classes/fhir-enums";
import {FhirService} from "./FhirService";
import {NitTools} from "../classes/NursitTools";
import * as environment from "../../../config/environment.json";
import {UserService} from "./UserService";
import {ConfigService} from "./ConfigService";
import {translations} from "../classes/translations";

const moment = require("moment");

export class QuestionnaireService {
    public static get ListResult(): IQuestionnaireList {
        return this.__listResult
    }

    public static get NitQuestionnairesUrl(): string {
        return ConfigService.NursItQuestionnaireCanonical || "http://nursit-institute.com/fhir/Questionnaire";
    }

    static __listResult: IQuestionnaireList = undefined;
    static __questionnaires: any[] = undefined;
    private static __createQuestionnaireListCache: IQuestionnaireList;
    public isInitialized: boolean = false;
    fhirService: FhirService;

    constructor() {
        this.fhirService = new FhirService();
    }

    public static UseFhirVersionSystem: boolean = false;

    public get loaded(): boolean {
        return QuestionnaireService.loaded;
    }

    public get listResult(): IQuestionnaireList {
        return QuestionnaireService.__listResult;
    }

    public get questionnaires(): any[] {
        return QuestionnaireService.__questionnaires;
    }

    public static get loaded(): boolean {
        return typeof this.__listResult !== "undefined";
    }

    public static GetQuestionnaireByNameDirect(name: string) {
        if (!name) {
            console.warn("No QuestionnaireName provided!");
            return undefined;
        }

        let qr = QuestionnaireService.__questionnaires.find(o => (o && o.name || o.title || o.url)
            && (
                String(o.url).indexOf('/' + name) > -1 ||
                (o.name || o.title).toUpperCase() === name.toUpperCase()
            )
        );

        return qr;
    }

    public static GetQuestionnaireDirect(idOrReference: string | object): any {
        if (!idOrReference) {
            console.warn('No Id or Reference given in GetQuestionnaireDirect()')
            if (ConfigService.Debug)
                debugger;

            return undefined;
        }

        if (FhirService.FhirVersion <= 3 && typeof idOrReference === "object" && idOrReference["reference"]) {
            // default handling, when the idOrReference is something like { reference: "Questionnaire/123" } which is the case on Fhir DTSU3 and lower:
            let ref = idOrReference["reference"];
            if (!ref) return undefined;

            // remove version information from the reference
            if (ref.indexOf('/_history') > -1) ref = ref.split('/_history')[0]; // Questionnaire/123/_history/2 -> Questionnaire/123
            ref = ref.split('/')[1]; // Questionnaire/123 -> 123

            if (ref.indexOf('|') > -1)
                ref = ref.split('|')[0]; // Questionnaire/123|1.0.0 -> 123

            return QuestionnaireService.__questionnaires.find(o => o.id === ref || o.name === ref);
        }

        if (FhirService.FhirVersion >= 4 && typeof idOrReference === "string") {
            // when it is R4 then the Response.Questionnaire is a canonical link like: "http://nursit-institute.com/Questionnaires/CareITAssessmentIntensiv" (seealso: QuestionnaireService.NitQuestionnairesUrl)
            let ref = String(idOrReference);
            // remove any informations from the questionnaire link
            if (ref.indexOf('?') > -1) ref = ref.split('?')[0]; // http://../Questionnaires/CareITAssessmentIntensiv?_history=1 -> http://../Questionnaires/CareITAssessmentIntensiv
            const arr: string[] = ref.split('/');
            let name = arr[arr.length - 1].toUpperCase();
            let id = Tools.StripId(idOrReference);

            return QuestionnaireService.__questionnaires.find(o => o.id == id || o.url?.endsWith(idOrReference) || String(o.name).toUpperCase() === name);
        }

        console.warn(`Don't know how to parse Questionnaire Reference (Fhir Version: ${FhirService.FhirVersion}): `, idOrReference);

        return idOrReference;
    }

    public static GetQuestionnaireByName(name: string, throwIfNotExists: boolean = true): Promise<any> {
        return new Promise<any>((resolve, reject) => {
            if (!name) {
                let msg = ("Empty name given in GetQuestionnaireByName");
                console.warn(msg);
                reject(msg);
                return;
            }

            const resultFast = QuestionnaireService.GetQuestionnaireByNameDirect(name);
            if (resultFast) {
                resolve(resultFast);
                return;
            }

            QuestionnaireService.Fetch()
                .then((result: any[]) => {
                    let qr = result.find(o => o.name && o.name.toUpperCase() === name.toUpperCase());
                    if (!qr) qr = result.find(o => o.title && o.title.toUpperCase() === name.toUpperCase());
                    if (qr) {
                        if (!QuestionnaireService.__questionnaires) QuestionnaireService.__questionnaires = [];
                        QuestionnaireService.__questionnaires.push(qr);
                        resolve(qr);
                    } else {
                        let msg = translations.translate('questionnaire_not_found');
                        msg = msg.replace('%NAME%', name);
                        console.warn(msg);

                        if (throwIfNotExists) reject(msg);
                        else resolve(undefined);
                    }

                    return;
                })
                .catch(error => {
                    console.warn(error);
                    reject(error);
                })
        });
    }

    public static GetResponseByIdDirect(patient, id: string) {
        if (!patient) return undefined;
        if (id.indexOf('/') > -1) id = id.split('/')[1];
        if (id.indexOf('urn:uuid:') > -1) {
            console.warn('Invalid URL for GetResponse:' + id);
            return undefined;
        }

        return patient.questionnaireResponses.find(o => o.id === id);
    }

    public static GetResponseById(patient, id: string): Promise<any> {
        return new Promise<any>((resolve, reject) => {
            if (id.indexOf('/_history') > -1) {
                id = id.split('/_history')[0];
            }

            if (id && id.indexOf('/') > -1) {
                let type = id.split('/')[0];
                if (type == fhirEnums.ResourceType.questionnaireResponse) {
                    id = id.split('/')[1];
                }
            }

            if (id.indexOf('urn:uuid:') > -1) {
                const err = 'Invalid URL for GetResponse:' + id;
                console.warn(err);
                resolve(undefined);
                return;
            }

            let result = patient ? patient.questionnaireResponses.find(o => o.id === id) : [];

            if (result || !patient) {
                resolve(result);
                return;
            }

            Fhir.Rest.Get(`${fhirEnums.ResourceType.questionnaireResponse}/${id}`)
                .then((qResult: any) => {
                    result = qResult;
                    PatientService.AddQuestionnaireResponse(patient, result);

                    resolve(result);
                    return;
                })
                .catch(error => {
                    if (!ConfigService.IsTest) {
                        console.warn(error);
                        console.warn("Error when getting QuestionnaireResponse with id: " + id);
                    }
                    resolve(undefined);
                });
        });
    }

    /**
     * Get the query parameter for getting an url with questionnaire= parameter for specific questionnaire(s) respecting the current Fhir-Version, either as Canonical-Url or Reference
     * @param questionnaires the questionnaires to get the query for
     */
    public static GetQuestionnaireQueryUrl(...questionnaires) : string {
        const result = [];
        for (const questionnaire of questionnaires) {
            if(!questionnaire?.name) continue
            if (FhirService.FhirVersion >= 4) {
                const questionnaireUrls: string[] = [`http://nursit-institute.com/Questionnaires/${questionnaire.name}`];
                const url = questionnaire.url || `${QuestionnaireService.NitQuestionnairesUrl}/${questionnaire.name}`;
                if (url.indexOf('|') > -1) {
                    questionnaireUrls.push(url.split('|')[0]);
                    questionnaireUrls.push(url.split('|')[0] + '|');
                }

                result.push(questionnaireUrls.join(','));
            } else {
                result.push(`Questionnaire/${questionnaire.id}`);
            }
        }

        return result.join(',');
    }

    public static GetQuestionnaireById(id: string): Promise<any> {
        return new Promise<any>((resolve, reject) =>
            QuestionnaireService.Fetch()
                .then(() => {
                    resolve(QuestionnaireService.__findQuestionnaire(id));
                    return;
                })
                .catch(error => {
                    console.warn(error);
                    reject("Error when loading Questionnaiere");
                    return;
                })
        );
    }

    public getLatestResponseOfType(patient: PatientItem, questionnaireId: string, status?: fhirEnums.QuestionnaireResponseStatus[]) {
        return QuestionnaireService.GetLatestResponseOfType(patient, questionnaireId, status);
    }

    public static async GetLatestResponseOfTypeAsync(patient: PatientItem, questionnaireId: string, status?: fhirEnums.QuestionnaireResponseStatus[]): Promise<any> {
        await ConfigService.LoadConfigOverride(patient?.ward, patient);
        return QuestionnaireService.GetLatestResponseOfType(patient, questionnaireId, status);
    }

    public static GetLatestResponseOfName(patient: PatientItem, questionnaireName: string, status?: fhirEnums.QuestionnaireResponseStatus[]): any {
        const q = QuestionnaireService.GetQuestionnaireByNameDirect(questionnaireName);
        return this.GetLatestResponseOfType(patient, q ? q.id : undefined, status);
    }

    public static GetLatestResponseOfType(patient: PatientItem, questionnaireId: string, status?: fhirEnums.QuestionnaireResponseStatus[]): any {
        if (!questionnaireId) return undefined;

        if (!status || status.length === 0) {
            status = [
                fhirEnums.QuestionnaireResponseStatus.amended, fhirEnums.QuestionnaireResponseStatus.completed, fhirEnums.QuestionnaireResponseStatus.inProgress
            ]
        }

        if (FhirService.FhirVersion == 3)
            questionnaireId = NitTools.GetId(questionnaireId);
        else {
            questionnaireId = Tools.StripId(questionnaireId)
        }

        let arr = QuestionnaireService.GetResponsesOfType(patient, questionnaireId, status);
        if (arr.length === 0) return undefined;
        if (arr.length === 1) return arr[0];

        // sort from old to new
        arr.sort((a, b) => {
            if (a.authored && b.authored) {
                let d1 = new Date(a.authored);
                let d2 = new Date(b.authored);

                return d1.valueOf() - d2.valueOf();
            }

            // no authored information provided than fall back to meta.lastUpdated
            if (!a.meta) {
                a.meta = {
                    lastUpdated: new Date(new Date().setFullYear(2000)).toJSON()
                };
            }

            if (!b.meta) {
                b.meta = {
                    lastUpdated: new Date(new Date().setFullYear(2000)).toJSON()
                };
            }

            return new Date(a.meta.lastUpdated).valueOf() - new Date(b.meta.lastUpdated).valueOf();
        });

        // return newest
        return arr[arr.length - 1];
    }

    /**
     * Gets a list of QuestionaireResponses from the given patient with the given type, ordered from oldest to newest authored property
     * @param patient the patient to get the list from
     * @param questionnaireId the questionnaire id to get responses for
     * @param status the questionnaire status the response has to be in
     * @returns QuestionnaireResponse[] QuestionnaireResponse[] sorted from latest to newest
     */
    public static GetResponsesOfType(patient: PatientItem, questionnaireId: string, status?: fhirEnums.QuestionnaireResponseStatus[]): any[] {
        if (!questionnaireId || !patient) return [];

        const pId = Fhir.Tools.StripId(patient.id);
        // do some cleanup, because of async behaviour, sometimes we get responses from wrong patient inserted when fhir server reacts slowly, and it was a fire&forget save
        if (patient?.questionnaireResponses)
            patient.questionnaireResponses = patient.questionnaireResponses.filter(o => Fhir.Tools.StripId(o.subject?.reference) === pId);
        else return [];

        questionnaireId = Fhir.Tools.StripId(questionnaireId);

        if (!status || status.length === 0) {
            status = [
                fhirEnums.QuestionnaireResponseStatus.inProgress,
                fhirEnums.QuestionnaireResponseStatus.completed,
                fhirEnums.QuestionnaireResponseStatus.amended,
            ]
        }

        let stati = [];
        status.forEach(state => {
            stati.push(String(state))
        });

        if (!patient.questionnaireResponses)
            patient.questionnaireResponses = [];

        let arr = patient.questionnaireResponses.filter(o => o &&
            o.questionnaire && o.questionnaire.reference
            && o.questionnaire.reference.indexOf(`Questionnaire/${questionnaireId}`) > -1
            && stati.indexOf(String(o.status)) > -1);

        if (FhirService.FhirVersion >= 4) {
            const q = this.__questionnaires.find(o => o.id == questionnaireId);
            if (q) {
                const questionnaireName = q.name;

                arr = patient.questionnaireResponses.filter(o => o &&
                    String(o.questionnaire).toUpperCase().indexOf(`/${questionnaireName.toUpperCase()}`) > -1
                    && stati.indexOf(String(o.status)) > -1);
            }
        }

        arr.sort((a, b) => {
            let d1 = moment(a.authored || new Date()).toDate();
            let d2 = moment(b.authored || new Date()).toDate();
            return d1.valueOf() - d2.valueOf();
        });

        return arr;
    }

    /**
     * Get a list of all Questionnaire ids as IQuestionnaireList
     * @returns a new instance of the IQuestionnaireList
     */
    public static GetQuestionnaireIds(): Promise<IQuestionnaireList> {
        return new Promise<IQuestionnaireList>(async (resolve, reject) => {
            await QuestionnaireService.Fetch()
                .then((arr: []) => {
                    // is called in Fetch, so no need to do it once more: this.__listResult = this.__createQuestionnaireList(arr);
                    return resolve(this.__createQuestionnaireList(arr));
                })
                .catch(error => {
                    console.warn(error);
                    return reject("Error when getting Questionnaires")
                });
        })
    }

    public static ClearCache() {
        this.__listResult = undefined;
        this.__questionnaires = undefined;
    }

    private static _moveDisplayItems(item, questionnaire) {
        if (item.item) {
            item.item.forEach(subItem => {
                if (subItem.linkId.indexOf('Display_') === 0) {
                    let relationId = subItem.linkId.substr(8);
                    let relationItem = Fhir.Questionnaire.GetQuestionnaireItemByLinkId(questionnaire, relationId);
                    if (relationItem && subItem.text) {
                        let text = subItem.text;
                        text = text.replace(/<br>/gi, "<br />");
                        text = text.replace(/\n/g, '<br />');

                        Fhir.Tools.SetExtension(relationItem, NitTools.IncludeTrailingSlash(environment.nursItStructureDefinition) + "questionnaire-item-comment", text);
                    }
                }
            });

            item.item = item.item.filter(o => o.linkId.indexOf('Display_') === -1);
        }
    }

    private static moveDisplayItems(questionnaire) {
        if (!questionnaire || !questionnaire.item) return;
        questionnaire.item.forEach(item => this._moveDisplayItems(item, questionnaire));
    }

    private static _fetchingQuestionnaires;

    private static waitForQuestionnaireService(): Promise<any> {
        return new Promise<any>(async (resolve) => {
            if (!QuestionnaireService._fetchingQuestionnaires) {
                resolve(true);
            } else {
                window.setTimeout(async () => {
                    await QuestionnaireService.waitForQuestionnaireService();
                    resolve(true);
                }, 100);
            }
        })
    }

    /** Load the Questionnaires from the Server or cache */
    public static Fetch(checkStatus: boolean = true): Promise<any[]> {
        return new Promise<any[]>(async (resolve, reject) => {
            if (QuestionnaireService.__questionnaires && QuestionnaireService.__questionnaires.length > 0) {
                return resolve(QuestionnaireService.__questionnaires);
            }

            if (!ConfigService.Debug && sessionStorage.getItem('useQuestionnaireCache') === '1') {
                let s = sessionStorage.getItem('questionnaireCache');
                if (s) {
                    try {
                        let tmp = JSON.parse(s);
                        // only use the cached values if restoring was correct; tmp exists, is an array and hast at least 2 items
                        if (!ConfigService.Debug && NitTools.IsArray(tmp) && tmp.length > 2) {
                            QuestionnaireService.__questionnaires = NitTools.Clone(tmp);
                            this.__listResult = this.__createQuestionnaireList(QuestionnaireService.__questionnaires);
                            if (ConfigService.Debug)
                                console.debug('Using Questionnaires from Cache');

                            resolve(QuestionnaireService.__questionnaires);
                            return
                        } else {
                            delete sessionStorage["questionnaireCache"];
                            delete sessionStorage["useQuestionnaireCache"];
                        }
                    } catch (e) {
                        if (ConfigService.Debug) console.warn('Error when getting Questionnaires from Cache:\n' + e.message);
                    }
                }
            }

            if (QuestionnaireService._fetchingQuestionnaires) {
                await QuestionnaireService.waitForQuestionnaireService();

                if (this.__questionnaires && this.__questionnaires.length > 0) {
                    resolve(this.__questionnaires);
                    return;
                }
            } else {
                QuestionnaireService._fetchingQuestionnaires = true;
            }

            const fhirService = new FhirService();

            if (UserService.Login.usePrincipa) checkStatus = false;

            fhirService.fetch(`Questionnaire${checkStatus ? "?status=active" : ""}`)
                .then((result: any[]) => {
                    function byteCount(s: string) {
                        let size = encodeURI(s).split(/%..|./).length - 1;
                        if (Math.round) return Math.round(size / 1024);
                        else if (Math.trunc) return Math.trunc(size / 1024);
                        else return size / 1024;
                    }

                    result.forEach(q => {
                        if (!q.status) {
                            q.status = fhirEnums.PublicationStatus.draft;
                        }

                        if (!q.name && q.title) q.name = q.title;
                        if (q.name && !q.title) q.title = q.name;
                        const linkIds = Fhir.Questionnaire.GetAllQuestionnaireItemLinkIds(q);

                        for (const linkId of linkIds) { // R4 fix
                            const item = Fhir.Questionnaire.GetQuestionnaireItemByLinkId(q, linkId);
                            if (item && !item.option && item["answerOptions"])
                                item.option = item["answerOption"];
                        }

                        Fhir.Questionnaire.MoveMultiSelectModifierExtension(q);
                        Fhir.Questionnaire.MoveCalculatedFields(q);

                        this.moveDisplayItems(q);
                    });

                    QuestionnaireService.__questionnaires = result;
                    this.__listResult = this.__createQuestionnaireList(result);

                    // create Questionnaire-Cache
                    try {
                        if (!ConfigService.IsTest) {
                            let questionnairesString = JSON.stringify(QuestionnaireService.__questionnaires);
                            let byteSize = byteCount(questionnairesString);
                            if (ConfigService.Debug) console.debug('ByteSize of Questionnaires is ' + byteSize + ' Bytes');
                            if (!ConfigService.Debug && byteSize < 2048) {
                                sessionStorage.setItem('questionnaireCache', JSON.stringify(QuestionnaireService.__questionnaires));
                                let s = sessionStorage.getItem('questionnaireCache');
                                if (s) {
                                    let tmp = JSON.parse(s);
                                    if (tmp && NitTools.IsArray(tmp) && tmp.length > 2) {
                                        sessionStorage.setItem('useQuestionnaireCache', '1');
                                    }
                                }
                            } else {
                                if (ConfigService.Debug) console.debug('Not storing Questionnaires because the size would exceed 2MB or it is DEBUG Version');
                            }
                        }
                    } catch (e) {
                        if (ConfigService.Debug && !ConfigService.IsTest) {
                            console.warn('Error when caching Questionnaires:\n' + e.message);
                            sessionStorage.removeItem('questionnaireCache');
                            sessionStorage.removeItem('useQuestionnaireCache');
                        }
                    }

                    QuestionnaireService._fetchingQuestionnaires = false;

                    return resolve(QuestionnaireService.__questionnaires);
                })
                .catch(error => {
                    QuestionnaireService._fetchingQuestionnaires = false;
                    return reject(error);
                })
        });
    }

    public static get Questionnaires(): any[] {
        if (!this.__questionnaires) return [];

        return this.__questionnaires.filter(o => o.status === 'active');
    }

    private static __findQuestionnaire(id: string): any {
        if (!id) {
            console.warn("[QuestionnaireService] No Questionnaire-Id specified in QuestionnaireService::__findQuestionnaire()");
            return undefined;
        }

        if (id.indexOf('/') > -1) {
            id = id.split('/')[1];
        }

        let r = QuestionnaireService.__questionnaires.find(o => o.id === id);

        if (!r) {
            console.warn(`[QuestionnaireService] Questionnaire with id ${id} does not exist (called QuestionnaireService::__findQuestionnaire('${id}') )`);
        }

        return r;
    }

    private static getQuestionnaireIdFromArray(arr: any[], name: string): string {
        name = name.toUpperCase();
        let q = arr.find(o => o.name && o.name.toUpperCase() === name);
        if (!q) {
            q = arr.find(o => o.title && o.title.toUpperCase() === name);

            if (q) {
                q.name = q.title;
            }
        }

        return q ? q.id : undefined;
    }

    static __createQuestionnaireList(arr: any[]): IQuestionnaireList {
        if (!this.__createQuestionnaireListCache) {
            this.__createQuestionnaireListCache = {
                QAdditionalInfoId: QuestionnaireService.getQuestionnaireIdFromArray(arr, "CareITEncounterAdditionalInfo"),
                QDiagnosisId: QuestionnaireService.getQuestionnaireIdFromArray(arr, "CareITDiagnosis"),
                QAssessmentId: QuestionnaireService.getQuestionnaireIdFromArray(arr, ConfigService.GetFormSettings('assessment').questionnaireName),
                QFallId: QuestionnaireService.getQuestionnaireIdFromArray(arr, ConfigService.GetFormSettings('fall').questionnaireName),
                QBarthelIndexId: QuestionnaireService.getQuestionnaireIdFromArray(arr, ConfigService.GetFormSettings('barthelindex').questionnaireName),
                QBarthelIndexExId: QuestionnaireService.getQuestionnaireIdFromArray(arr, ConfigService.GetFormSettings('barthelindexEx').questionnaireName),
                QFimId: QuestionnaireService.getQuestionnaireIdFromArray(arr, ConfigService.GetFormSettings('fim').questionnaireName),
                QDischargeManagementId: QuestionnaireService.getQuestionnaireIdFromArray(arr, ConfigService.GetFormSettings('discharge').questionnaireName),
                QAnamnesisId: QuestionnaireService.getQuestionnaireIdFromArray(arr, ConfigService.GetFormSettings('anamnesis').questionnaireName),
                QWoundId: QuestionnaireService.getQuestionnaireIdFromArray(arr, "CareITWound_wound"),
                QVerlegungId: QuestionnaireService.getQuestionnaireIdFromArray(arr, ConfigService.GetFormSettings('transfer').questionnaireName),
                QIsolationId: QuestionnaireService.getQuestionnaireIdFromArray(arr, ConfigService.GetFormSettings('isolation').questionnaireName)
            };
        }

        return this.__createQuestionnaireListCache;
    }

    public clearCache(): QuestionnaireService {
        QuestionnaireService.ClearCache();
        return this;
    }

    public async fetch(checkStatus: boolean = true): Promise<any[]> {
        return new Promise<any[]>(async (resolve, reject) => {
            try {
                let result = await QuestionnaireService.Fetch(checkStatus);
                if (result && result.length > 0) this.isInitialized = true;
                resolve(result);
            } catch (e) {
                reject("Error when fetching Questionnaires " + (e.message ? e.message : ''));
            }
        })
    }

    public getQuestionnaireByName(name: string): Promise<any> {
        return QuestionnaireService.GetQuestionnaireByName(name);
    }

    public getQuestionnaireByNameDirect(name: string): any {
        return QuestionnaireService.GetQuestionnaireByNameDirect(name);
    }

    public getQuestionnaireDirect(idOrReference: object | string): any {
        return QuestionnaireService.GetQuestionnaireDirect(idOrReference);
    }

    public getQuestionnaireById(id: string): Promise<any> {
        return QuestionnaireService.GetQuestionnaireById(id);
    }

    public getQuestionnaireIds(): Promise<IQuestionnaireList> {
        return QuestionnaireService.GetQuestionnaireIds();
    }

    /**
     * Gets a list of QuestionaireResponses from the given patient with the given type, ordered from oldest to newest authored property
     * @param patient the patient to get the list from
     * @param questionnaireId the questionnaire id to get responses for
     * @param status the questionnaire status the response has to be in
     * @returns QuestionnaireResponse[] QuestionnaireResponse[] sorted from latest to newest
     */
    public getResponsesOfType(patient: PatientItem, questionnaireId: string, status?: fhirEnums.QuestionnaireResponseStatus[]): any[] {
        return QuestionnaireService.GetResponsesOfType(patient, questionnaireId, status);
    }

    static _versionCache: any[];

    public async loadVersion(questionnaireId: string, version: string): Promise<any> {
        if (!QuestionnaireService._versionCache) QuestionnaireService._versionCache = [];

        // first check whether the older version has been cached
        let existing = QuestionnaireService._versionCache.find(o => o.meta && o.meta.versionId && o.meta.versionId === version);
        if (existing) return existing;

        // not found in cache, so download it...
        let url = `Questionnaire/${questionnaireId}/_history/${version}`;
        let result = <any>await this.fhirService.get(url);

        // .. save it to the cache
        QuestionnaireService._versionCache.push(result);

        // .. and give back the loaded.
        if (ConfigService.Debug) console.debug(QuestionnaireService._versionCache);
        return result;
    }
}

export interface IQuestionnaireList {
    QAdditionalInfoId: string;
    QDiagnosisId: string;
    QAssessmentId: string;
    QFallId: string;
    QBarthelIndexId: string;
    QBarthelIndexExId: string;
    QFimId: string;
    QDischargeManagementId: string;
    QAnamnesisId: string;
    QWoundId: string;
    QVerlegungId: string;
    QIsolationId: string;
}
